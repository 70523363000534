<template>
	<div>
		<div class="row my-4">
			<h1 class="col-sm-12 text-right">{{ tipo_fideicomiso == 1 ? 'Fideicomisos' : 'Convenios'}}</h1>
		</div>

		<DataGrid :config="dataGridConfig" :data="fideicomisos" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2" v-if="$auth.can('administrador_de_garantias','crear_fideicomisos')"><button class="btn secondary-btn" @click="fideicomiso_modal = 1">Agregar Fideicomiso</button></div>
				<div class="col-sm-2" v-if="$auth.can('administrador_de_garantias','editar_fideicomisos')"><button class="btn complementary-btn" @click="editar_fideicomiso">Editar Fideicomiso</button></div>
				<div class="col-sm-2"><button class="btn principal-btn" @click="ver_fideicomiso">Ver Fideicomiso</button></div>
				<div class="col-sm-2"><button class="btn warning-btn" @click="obtener_fideicomisos">Recargar</button></div>
			</div>
		</DataGrid>

		<Modal v-if="fideicomiso_modal != 0" :options="{width: '90vw', name: 'modal_fideicomiso',close: true}" @close="cancelar_fideicomiso">
			<div class="title">{{ titulo_modelo }}</div>
			<div :class="'body'+(fideicomiso_modal == 3 ? ' readonly' : '')">
				<fieldset>
					<legend>Fideicomiso</legend>

					<div class="row form-group">
						<label for="fideicomiso_fedeicomiso" class="col-form-label col-sm-3 col-md-2">Fideicomiso</label>
						<div class="col-sm-3 col-md-4"><input v-model="fideicomiso.no_fideicomiso" name="fideicomiso_fedeicomiso" id="fideicomiso_fedeicomiso" type="text" class="form-control" /></div>
						<label for="fideicomiso_estatus" class="col-form-label col-sm-3 col-md-2">Estatus</label>
						<div class="col-sm-3 col-md-4">
							<select v-model="fideicomiso.estatus" name="fideicomiso_estatus" id="fideicomiso_estatus" class="form-control">
								<option value="1">Activo</option>
								<option value="0">Inactivo</option>
								<option value="2">Caducado</option>
							</select>
						</div>
					</div>
					<div class="row form-group">
						<label for="fideicomiso_tipo_fedeicomiso" class="col-form-label col-sm-3 col-md-2">Tipo fideicomiso</label>
						<div class="col-sm-3 col-md-4">
							<select v-model="fideicomiso.tipo_fideicomiso" name="fideicomiso_tipo_fedeicomiso" id="fideicomiso_tipo_fedeicomiso" class="form-control">
								<option v-for="tipo_fideicomiso in tipo_fideicomisos" :value="tipo_fideicomiso.valor">{{ tipo_fideicomiso.nombre}}</option>
							</select>
						</div>
						<label for="fideicomiso_descripcion" class="col-form-label col-sm-3 col-md-2">Descripción</label>
						<div class="col-sm-9 col-md-4"><input v-model="fideicomiso.descripcion" name="fideicomiso_descripcion" id="fideicomiso_descripcion" type="text" class="form-control" /></div>
					</div>
					<div class="row form-group">
						<label for="fideicomiso_superficie" class="col-form-label col-sm-3 col-md-2">Superficie</label>
						<div class="col-sm-3 col-md-4">
							<InputCompuesto v-model="fideicomiso.superficie" label_right="m2" name="fideicomiso.superficie" id="fideicomiso.superficie"/>
						</div>
						<label for="fideicomiso.monto_credito" class="col-form-label col-sm-3 col-md-2">Monto crédito</label>
						<div class="col-sm-3 col-md-4">
							<InputCompuesto v-model="fideicomiso.monto_credito" label="$" name="fideicomiso.monto_credito" id="fideicomiso.monto_credito" :formats="['moneyFormat']"/>
						</div>
					</div>
					<div class="row form-group">
						<label for="fideicomiso_rfc" class="col-form-label col-sm-3 col-md-2">Fecha escritura</label>
						<div class="col-sm-3 col-md-4"><input v-model="fideicomiso.fecha_escritura" name="fideicomiso_rfc" id="fideicomiso_rfc" type="date" class="form-control" /></div>
						<label for="fideicomiso.proporcion_deseada" class="col-form-label col-sm-3 col-md-2">Proporcion deseada</label>
						<div class="col-sm-3 col-md-4"><input v-model="fideicomiso.proporcion_deseada" name="fideicomiso.proporcion_deseada" id="fideicomiso.proporcion_deseada" type="text" class="form-control" /></div>
					</div>
					<div class="row form-group">
						<label for="fideicomiso_direccion" class="col-form-label col-sm-3 col-md-2">Dirección</label>
						<div class="col-sm-9 col-md-10"><input v-model="fideicomiso.direccion" name="fideicomiso_direccion" id="fideicomiso_direccion" type="text" class="form-control" /></div>
					</div>
					<div class="row form-group">
						<label for="fideicomiso.notario" class="col-form-label col-sm-3 col-md-2">Notario</label>
						<div class="col-sm-3 col-md-4"><input v-model="fideicomiso.notario" name="fideicomiso.notario" id="fideicomiso.notario" type="text" class="form-control" /></div>
						<label for="fideicomiso.otorgado_garantia" class="col-form-label col-sm-3 col-md-2">Otorgado en garantia</label>
						<div class="col-sm-3 col-md-4">
							<select v-model="fideicomiso.otorgado_garantia" name="fideicomiso.otorgado_garantia" id="fideicomiso.otorgado_garantia" class="form-control">
								<option value="1">Sí</option>
								<option value="0">No</option>
							</select>
						</div>
					</div>
					<div v-if="!fideicomiso.id" class="row form-group d-flex justify-content-end">
						<div class="col-sm-3 col-md-4">
							<button class="btn secondary-btn" @click="crear_fideicomiso">Continuar</button>
						</div>
					</div>
				</fieldset>

				<fieldset v-if="fideicomiso.id">
					<legend>Partes</legend>

					<table class="mb-3">
						<tr>
							<th style="width: 48%;">Fideicomitentes</th>
							<th style="width: 47%;">Tipo</th>
							<th style="width: 5%;">&nbsp;</th>
						</tr>
						<template v-if="fideicomiso.fideicomitentes.length > 0">
						<tr v-for="fideicomitente in fideicomiso.fideicomitentes">
							<td>{{ fideicomitente.usuario.nombre }}</td>
							<td>{{ fideicomitente.nivel }}</td>
							<td v-if="$auth.can('administrador_de_garantias','desasignar_usuario')" class="text-center"><div class="icon-cross" style="font-size: 0.7em" @click="quitar_usuario" :data-usuario_id="fideicomitente.usuario_id" data-tipo="fideicomitente"></div></td>
						</tr>
						</template>
						<template v-else>
							<tr>
								<td>Sin fideicomitentes</td>
							</tr>
						</template>
					</table>
					<table class="mb-3">
						<tr>
							<th style="width: 48%;">Fideicomisarios</th>
							<th style="width: 47%;">Tipo</th>
							<th style="width: 5%;">&nbsp;</th>
						</tr>
						<template v-if="fideicomiso.fideicomisarios.length > 0">
						<tr v-for="fideicomisario in fideicomiso.fideicomisarios">
							<td>{{ fideicomisario.usuario.nombre }}</td>
							<td>{{ fideicomisario.nivel }}</td>
							<td v-if="$auth.can('administrador_de_garantias','desasignar_usuario')" class="text-center"><div class="icon-cross" style="font-size: 0.7em" @click="quitar_usuario" :data-usuario_id="fideicomisario.usuario_id" data-tipo="fideicomisario"></div></td>
						</tr>
						</template>
						<template v-else>
							<tr>
								<td>Sin fideicomisarios</td>
							</tr>
						</template>
					</table>

					<div v-if="fideicomiso_modal == 1 || fideicomiso_modal == 2" class="fideicomiso-form-model">
						<div class="row form-group mt-4">
							<div class="col-sm-12 text-light bg-dark py-2 px-2">Agregar nueva parte</div>
						</div>
						<form @submit.prevent="agregar_usuario">
							<div class="row form-group">

								<div class="col-sm-3">
									<select v-model="fideicomiso_tipo" name="tipo_de_parte" id="tipo_de_parte" class="form-control">
										<option value="0">Tipo de parte</option>
										<option value="fideicomitente">Fideicomitente</option>
										<option value="fideicomisario">Fideicomisario</option>
									</select>
								</div>
								<div class="col-sm-3">
									<select ref="usuario_nuevo" name="usuario_nuevo" id="usuario_nuevo" class="form-control">
										<option v-for="usuario in usuarios_validos" :value="usuario.id">{{ usuario.nombre}}</option>
									</select>
								</div>
								<div class="col-sm-3">
									<input ref="usuario_nivel" id="usuario_nivel" name="usuario_nivel" type="text" class="form-control" placeholder="Nivel">
								</div>
								<div class="col-sm-3">
									<button class="btn secondary-btn">Agregar</button>
								</div>
							</div>
						</form>
					</div>
				</fieldset>

				<fieldset v-if="fideicomiso.id">
					<legend>Patrimonio</legend>

					<template v-for="(patrimonio,index) in fideicomiso.patrimonios">
						<div class="row form-group">
							<label for="" class="col-form-label col-sm-2">Número de patrimonio</label>
							<div class="col-sm-10"><div class="form-control">{{ (index+1) }}</div></div>
						</div>
						<div class="row form-group">
							<label :for="'fideicomiso.patrimonio.'+index+'.patrimonio'" class="col-form-label col-sm-3 col-md-2">Descripción</label>
							<div class="col-sm-3 col-md-4"><InputCompuesto v-model="patrimonio.descripcion" type="text" :name="'fideicomiso.patrimonio.'+index+'.patrimonio'" :id="'fideicomiso.patrimonio.'+index+'.patrimonio'"/></div>
							<label :for="'fideicomiso.patrimonio.'+index+'.valor'" class="col-form-label col-sm-3 col-md-2">Valor</label>
							<div class="col-sm-3 col-md-4">
								<InputCompuesto v-model="patrimonio.valor" label="$" :name="'fideicomiso.patrimonio.'+index+'.valor'" :id="'fideicomiso.patrimonio.'+index+'.valor'" :formats="['moneyFormat']"/>
							</div>
						</div>
						<div class="row form-group">
							<label :for="'fideicomiso.patrimonio.'+index+'.superficie'" class="col-form-label col-sm-3 col-md-2">Superficie</label>
							<div class="col-sm-3 col-md-4">
								<InputCompuesto v-model="patrimonio.superficie" label_right="m2" />
							</div>
							<label :for="'fideicomiso.patrimonio.'+index+'.en_garantia'" class="col-form-label col-sm-3 col-md-2">En garantia</label>
							<div class="col-sm-3 col-md-4"><SwitchBPB v-model="patrimonio.en_garantia" :id="'fideicomiso.patrimonio.'+index+'.en_garantia'" :name="'fideicomiso.patrimonio.'+index+'.en_garantia'" textoff="No" texton="Sí" value="1" /></div>
						</div>
						<div class="row form-group">
							<label :for="'fideicomiso.patrimonio.'+index+'.calle'" class="col-form-label col-sm-3 col-md-2">Calle</label>
							<div class="col-sm-3 col-md-4"><InputCompuesto v-model="patrimonio.calle" type="text" :name="'fideicomiso.patrimonio.'+index+'.calle'" :id="'fideicomiso.patrimonio.'+index+'.calle'"/></div>
							<label :for="'fideicomiso.patrimonio.'+index+'.municipio'" class="col-form-label col-sm-3 col-md-2">Municipio</label>
							<div class="col-sm-3 col-md-4"><InputCompuesto v-model="patrimonio.municipio" type="text" :name="'fideicomiso.patrimonio.'+index+'.municipio'" :id="'fideicomiso.patrimonio.'+index+'.municipio'"/></div>
						</div>
						<div class="row form-group">
							<label :for="'fideicomiso.patrimonio.'+index+'.cp'" class="col-form-label col-sm-3 col-md-2">CP</label>
							<div class="col-sm-3 col-md-4"><InputCompuesto v-model="patrimonio.cp" type="text" :name="'fideicomiso.patrimonio.'+index+'.cp'" :id="'fideicomiso.patrimonio.'+index+'.cp'"/></div>
							<label :for="'fideicomiso.patrimonio.'+index+'.estado'" class="col-form-label col-sm-3 col-md-2">Estado</label>
							<div class="col-sm-3 col-md-4">
								<select v-model="patrimonio.estado" :name="'fideicomiso.patrimonio.'+index+'.estado'" :id="'fideicomiso.patrimonio.'+index+'.estado'" class="form-control">
									<option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
								</select>
							</div>
						</div>
						<div class="row form-group">
							<label :for="'fideicomiso.patrimonio.'+index+'.pais'" class="col-form-label col-sm-3 col-md-2">Pais</label>
							<div class="col-sm-3 col-md-4"><InputCompuesto v-model="patrimonio.pais" type="text" :name="'fideicomiso.patrimonio.'+index+'.pais'" :id="'fideicomiso.patrimonio.'+index+'.pais'"/></div>
							<label :for="'fideicomiso.patrimonio.'+index+'.numero_escrituras'" class="col-form-label col-sm-3 col-md-2">Número escrituras</label>
							<div class="col-sm-3 col-md-4"><InputCompuesto v-model="patrimonio.numero_escrituras" type="text" :name="'fideicomiso.patrimonio.'+index+'.numero_escrituras'" :id="'fideicomiso.patrimonio.'+index+'.numero_escrituras'"/></div>
						</div>
						<div v-if="$auth.can('administrador_de_garantias','desasignar_patrimonio')" class="row form-group">
							<label :for="'fideicomiso.patrimonio.'+index+'.fecha_escritura'" class="col-form-label col-sm-3 col-md-2">Fecha escrituras</label>
							<div class="col-sm-3 col-md-4"><InputCompuesto v-model="patrimonio.fecha_escrituras" type="date" :name="'fideicomiso.patrimonio.'+index+'.fecha_escritura'" :id="'fideicomiso.patrimonio.'+index+'.fecha_escritura'"/></div>
							<div v-if="fideicomiso_modal != 3" class="col-sm-3 offset-sm-3"><button class="btn danger-btn" :data-patrimonio="patrimonio.id" @click="quitar_patrimonio">Quitar patrimonio</button></div>
						</div>
					</template>

					<div v-if="(fideicomiso_modal == 1 || fideicomiso_modal == 2) && fideicomiso.fideicomisarios.length > 1" class="fideicomiso-form-model">
						<div class="row form-group mt-4">
							<div class="col-sm-12 text-light bg-dark py-2 px-2">Agregar inmueble</div>
						</div>

						<form @submit.prevent="agregar_patrimonio">
							<div class="row form-group">
								<div class="col-sm-9">
									<select ref="nuevo_patrimonio" name="nuevo_patrimonio" id="nuevo_patrimonio" class="form-control">
										<option v-for="(patrimonio, index) in patrimonios_validos" :value="patrimonio.id">{{ patrimonio.descripcion+', Valor: $'+patrimonio.valor }}</option>
									</select>
								</div>
								<div class="col-sm-3">
									<button class="btn secondary-btn">Agregar</button>
								</div>
							</div>
						</form>
					</div>
				</fieldset>
			</div>
			<div class="footer">
				<div v-if="fideicomiso_modal != 3" class="row d-flex justify-content-end">
					<div class="col-sm-2"><button class="btn secondary-btn" @click="crear_fideicomiso">Guardar</button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="cancelar_fideicomiso">Cancelar</button></div>
				</div>
				<div v-else class="row d-flex justify-content-end">
					<div class="col-sm-2"><button class="btn secondary-btn" @click="cancelar_fideicomiso">Cerrar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import api from '@/apps/garantias/api/fideicomisos'
	import apiUsuarios from '@/apps/garantias/api/usuarios'
	import apiPatrimonio from '@/apps/garantias/api/patrimonios'
	import apiOtros from '@/apps/garantias/api/otros'

	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'
	import Radio from '@/components/Form/Radio'
	import SwitchBPB from '@/components/Form/Switch'
	import InputCompuesto from '@/components/Form/InputCompuesto'

	export default {
		components: {
			DataGrid, Modal, Radio, SwitchBPB, InputCompuesto
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'fideicomisos'
					,cols: {
						no_fideicomiso: 'No fideicomiso'
						,descripcion: 'Descripción'
						,monto_credito: 'Monto Crédito'
						,estatus: 'Estatus'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						monto_credito: function(val, row, vue) {
							return '$'+vue.$helper.moneyFormat(val);
						}
						,estatus: function(val) {
							switch(val) {
								case 1:
									return 'Activo';
									break;
								case 2:
									return 'Inactivo';
									break;
							}
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionadas: []
				,fideicomisos: []
				,fideicomiso: {
					no_fideicomiso: null
					,descripcion: null
					,valor: null
					,superficie: null
					,otorgado_garantia: null
					,monto_credito: null
					,proporcion_deseada: null
					,direccion: null
					,notario: null
					,fecha_escritura: null
					,estatus: null
					,patrimonios: []
					,fideicomitentes: []
					,fideicomisarios: []
				}
				,fideicomiso_tipo: 0
				,fideicomiso_modal: 0
				,partes: []
				,patrimonios: []
				,fideicomitentes: []
				,fideicomisarios: []
				,usuarios: []
				,tipo_fideicomisos: []
			}
		}
		,mounted: function() {
			this.cargar_usuarios();
			this.obtener_estados();
			this.obtener_tipo_fideicomisos();
		}
		,methods: {
			dataGridActions: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this.obtener_fideicomisos();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,obtener_fideicomisos: async function() {
				try {
					let tipo = typeof this.$route.params.tipo != 'undefined' ? this.$route.params.tipo : 1;
					// this.$log.info('tipo', tipo)
					let res = (await api.obtener_fideicomisos_tipo(tipo, this.options)).data;

					this.fideicomisos = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,obtener_estados: async function() {
				try {
					this.estados = (await apiOtros.obtener_catalogo(3)).data.opciones;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,obtener_tipo_fideicomisos: async function() {
				try {
					this.tipo_fideicomisos = (await apiOtros.obtener_catalogo(5)).data.opciones;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,editar_fideicomiso: function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Debes seleccionar el fideicomiso a editar','error','alert');

				this.fideicomiso = this.seleccionadas[0];

				this.obtener_patrimonio_usuarios();

				this.fideicomiso_modal = 2;
			}
			,ver_fideicomiso: function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Debes seleccionar el fideicomiso a editar','error','alert');

				this.fideicomiso = this.seleccionadas[0];

				this.fideicomiso_modal = 3;

				setTimeout(function() {
					let modal = document.getElementsByClassName('modal_fideicomiso')[0];
					modal.querySelectorAll('input:not([type="button"]),select').forEach((input) => {
						input.disabled = true;
					});
				},100);
			}
			,cancelar_fideicomiso: function() {
				this.fideicomiso = {
					no_fideicomiso: null
					,administracion: 'propia'
					,nombre: null
					,constitucion: null
					,estado: 'activo'
					,tipo: null
					,producto: null
					,contribuyente: 'si'
					,individualizado: 'si'
					,rfc: null
					,abogado: null
					,objeto: null
					,fines: null
					,partes: []
					,honorario: {
						dias_descansos: null
						,cargos_moratorios: null
						,comentarios: null
						,importe: null
						,moneda: null
						,cliente: null
						,forma_cobro: null
						,tipo_cobro: null
						,periodicidad: null
						,moneda: null
						,iva: null
						,origen_recursos: null
						,aplicar_factor: null
						,cargo_honorarios: null
						,comentarios: null
						,conceptos: []
					}
					,patrimonio: {
						tipo: null
						,descripcion: null
						,inmuebles: []
					}
				}

				this.fideicomiso_modal = 0;
				this.seleccionar = false;
			}
			,money_format: function(val) {
				return val;
			}
			,obtener_patrimonio_usuarios: async function() {
				try {
					let ids = '';

					if (this.fideicomiso.fideicomisarios.length == 0)
						return;

					for(let i=0; i<this.fideicomiso.fideicomisarios.length; i++) {
						if (ids == '')
							ids += this.fideicomiso.fideicomisarios[i].usuario_id;
						else
							ids += ','+this.fideicomiso.fideicomisarios[i].usuario_id;
					}

					this.patrimonios = (await apiPatrimonio.obtener_patrimonios_usuario(ids)).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,agregar_patrimonio: async function() {
				let id = this.$refs.nuevo_patrimonio.value;

				try {
					this.fideicomiso = (await api.asignar_patrimonio(this.fideicomiso.id, id)).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cargar_usuarios: async function() {
				try {
					this.usuarios = (await apiUsuarios.obtener_usuarios_todos()).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,agregar_usuario: async function() {
				try {
					let nivel = this.$refs.usuario_nivel.value;

					if (nivel == '' || nivel == null)
						return this.$helper.showMessage('Error','Tienes que especificar el nivel de la parte','error','alert');

					this.fideicomiso = (await api.asignar_usuario(this.fideicomiso.id, this.fideicomiso_tipo, this.$refs.usuario_nuevo.value, nivel)).data;

					this.obtener_patrimonio_usuarios();

					this.fideicomiso_tipo = 0;
					this.$refs.usuario_nivel.value = null;
					this.$refs.usuario_nuevo.value = null;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,crear_fideicomiso: async function() {
				try {
					if (!this.fideicomiso.id)
						this.fideicomiso = (await api.crear_fideicomiso(this.fideicomiso)).data;
					else
						this.fideicomiso = (await api.editar_fideicomiso(this.fideicomiso.id, this.fideicomiso));

					this.obtener_fideicomisos();
					// this.cancelar_fideicomiso();
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,quitar_usuario: async function(e) {
				try {
					let usuario_id = e.target.getAttribute('data-usuario_id');
					let tipo = e.target.getAttribute('data-tipo');

					if (tipo == 'fideicomisario') {
						this.$log.info('fideicomisarios', this.fideicomiso.fideicomisarios.length);
						if (this.fideicomiso.fideicomisarios.length == 1) {
							return this.$helper.showMessage('Error','No puedes dejar el fideicomiso sin fideicomisario','error','alert');
						}
					}else {
						this.$log.info('fideicomitentes', this.fideicomiso.fideicomitentes.length);
						if (this.fideicomiso.fideicomitentes.length == 1) {
							return this.$helper.showMessage('Error','No puedes dejar el fideicomiso sin fideicomitente','error','alert');
						}
					}

					this.fideicomiso = (await api.quitar_usuario(this.fideicomiso.id, usuario_id, tipo)).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,quitar_patrimonio: async function(e) {
				try {
					if (this.fideicomiso.patrimonios.length == 1)
						return this.$helper.showMessage('Error','No puedes dejar el fideicomiso sin patrimonio','error','alert');

					let patrimonio = e.target.getAttribute('data-patrimonio');

					this.fideicomiso = (await api.quitar_patrimonio(this.fideicomiso.id, patrimonio)).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
		}
		,computed: {
			monto_credito: function() {
				return '$'+this.$helper.moneyFormat(this.fideicomiso.monto_credito);
			}
			,titulo_modelo: function() {
				switch(this.fideicomiso_modal) {
					case 1:
						return 'Crear fideicomiso';
						break;
					case 2:
						return 'Editar fideicomiso';
						break;
					case 3:
						return 'Fideicomiso '+this.fideicomiso.no_fideicomiso;
						break;
				}
			}
			,patrimonios_validos: function() {
				if (this.fideicomiso.fideicomisarios.length == 0)
					return [];

				let patrimonios_validos = [];

				this.patrimonios.forEach(patrimonio => {
					let encontrado = false;
					this.fideicomiso.patrimonios.forEach(patrimonio_dis => {
						if (patrimonio.id == patrimonio_dis.id)
							encontrado = true;
					});

					if (!encontrado)
						patrimonios_validos.forEach(patrimonio_dis => {
							if (patrimonio.id == patrimonio_dis.id)
								encontrado = true;
						});

					if (!encontrado)
						patrimonios_validos.push(patrimonio);
				});

				return patrimonios_validos;
			}
			,usuarios_validos: function() {
				let usuarios_validos = [];

				if (this.fideicomiso_tipo == 0)
					return usuarios_validos;

				this.$log.info('fideicomiso_tipo', this.fideicomiso_tipo);
				// this.$log.info('usuarios', usuarios_validos);

				this.usuarios.forEach(usuario => {
					let encontrado = false;

					if (this.fideicomiso_tipo == 'fideicomitente') {
						for(let i=0; i<this.fideicomiso.fideicomitentes.length; i++) {
							if (this.fideicomiso.fideicomitentes[i].usuario_id == usuario.id) {
								encontrado = true;
								i = this.fideicomiso.fideicomitentes.length;
							}
						};
					}else {
						for(let i=0; i<this.fideicomiso.fideicomisarios.length; i++) {
							if (this.fideicomiso.fideicomisarios[i].usuario_id == usuario.id) {
								encontrado = true;
								i=this.fideicomiso.fideicomisarios.length;
							}
						};
					}

					if (!encontrado)
						usuarios_validos.push(usuario);
				});

				return usuarios_validos;
			}
			,tipo_fideicomiso: function() {
				if (typeof this.$route.params.tipo != 'undefined')
					return this.$route.params.tipo;
				else
					return 1;
			}
		},
		watch: {
			'$route.params.tipo': {
				deep: true
				,handler: function() {
					this.$log.info('Cambio de tipo', this.$route.params.tipo);
					this.options.page = 1;
					this.obtener_fideicomisos();
				}
			}
		}
	}
</script>

<style lang="scss">
	.col-form-label {
		background-color: #E6E6E6;
	}

	textarea {
		height: 150px !important;
		resize: none;
	}

	fieldset table {
		width: 100%;

		tr {
			th {
				background-color: #CCCCCC;
				color: #000;
				padding: 8px 5px;
			}

			th:first-child:nth-last-child(1) {
				width: 100%;
			}

			th:first-child:nth-last-child(2),
			th:first-child:nth-last-child(2) ~ th {
				width: 50%;
			}

			th:first-child:nth-last-child(3),
			th:first-child:nth-last-child(3) ~ th {
				width: 33.3%;
			}
		}

		tr:nth-child(2n+1) {
			background-color: #E6E6E6;
		}
	}

	.honorarios_title {
		background-color: #E6E6E6;
		font-size: 1.3em;
		font-weight: bold;
	}

	.container_label {
		padding: 0px!important;

		label {
			width: 100%;
		}
	}

	.readonly {
		.fideicomiso-form-model {
			display: none;
		}
	}
</style>